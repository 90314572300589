<template>
  <layout name="HomeLayout">
    <div class="Home">
      <section class="uk-section uk-section-small">
        <div class="uk-container">
          <div class="uk-grid-medium uk-child-width-1-1" uk-grid>
            <div class="uk-text-center">
              <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
                <li><a href="index.html">Home</a></li>
                <li><span>News</span></li>
              </ul>
              <h1 class="uk-margin-small-top uk-margin-remove-bottom">News</h1></div>
            <div>
              <div class="uk-grid-medium" uk-grid>
                <section class="uk-width-1-1 uk-width-expand@m">
                  <section class="uk-card uk-card-default uk-card-small uk-card-body tm-ignore-container">
                    <ul class="uk-list uk-list-large uk-list-divider">
                      <li>
                        <article class="uk-article">
                          <div class="uk-article-body">
                            <div class="uk-article-meta uk-margin-xsmall-bottom">
                              <time>June 4, 2018</time>
                            </div>
                            <div><h3><a class="uk-link-heading" href="detail.html">This is title</a></h3></div>
                            <div class="uk-margin-small-top"><p>This is description This is description This is descriptionThis is description This is description This is description</p></div>
                          </div>
                        </article>
                      </li>

                      <li>
                        <article class="uk-article">
                          <div class="uk-article-body">
                            <div class="uk-article-meta uk-margin-xsmall-bottom">
                              <time>June 4, 2018</time>
                            </div>
                            <div><h3><a class="uk-link-heading" href="detail.html">This is title</a></h3></div>
                            <div class="uk-margin-small-top"><p>This is description This is description This is descriptionThis is description This is description This is description</p></div>
                          </div>
                        </article>
                      </li>
                      <li>
                        <article class="uk-article">
                          <div class="uk-article-body">
                            <div class="uk-article-meta uk-margin-xsmall-bottom">
                              <time>June 4, 2018</time>
                            </div>
                            <div><h3><a class="uk-link-heading" href="detail.html">This is title</a></h3></div>
                            <div class="uk-margin-small-top"><p>This is description This is description This is descriptionThis is description This is description This is description</p></div>
                          </div>
                        </article>
                      </li>
                      <li>
                        <article class="uk-article">
                          <div class="uk-article-body">
                            <div class="uk-article-meta uk-margin-xsmall-bottom">
                              <time>June 4, 2018</time>
                            </div>
                            <div><h3><a class="uk-link-heading" href="detail.html">This is title</a></h3></div>
                            <div class="uk-margin-small-top"><p>This is description This is description This is descriptionThis is description This is description This is description</p></div>
                          </div>
                        </article>
                      </li>
                      <li>
                        <article class="uk-article">
                          <div class="uk-article-body">
                            <div class="uk-article-meta uk-margin-xsmall-bottom">
                              <time>June 4, 2018</time>
                            </div>
                            <div><h3><a class="uk-link-heading" href="detail.html">This is title</a></h3></div>
                            <div class="uk-margin-small-top"><p>This is description This is description This is descriptionThis is description This is description This is description</p></div>
                          </div>
                        </article>
                      </li>
                      <li>
                        <article class="uk-article">
                          <div class="uk-article-body">
                            <div class="uk-article-meta uk-margin-xsmall-bottom">
                              <time>June 4, 2018</time>
                            </div>
                            <div><h3><a class="uk-link-heading" href="detail.html">This is title</a></h3></div>
                            <div class="uk-margin-small-top"><p>This is description This is description This is descriptionThis is description This is description This is description</p></div>
                          </div>
                        </article>
                      </li>
                      <li>
                        <article class="uk-article">
                          <div class="uk-article-body">
                            <div class="uk-article-meta uk-margin-xsmall-bottom">
                              <time>June 4, 2018</time>
                            </div>
                            <div><h3><a class="uk-link-heading" href="detail.html">This is title</a></h3></div>
                            <div class="uk-margin-small-top"><p>This is description This is description This is descriptionThis is description This is description This is description</p></div>
                          </div>
                        </article>
                      </li>

                    </ul>
                  </section>
                </section>
                <aside class="uk-width-1-4 uk-visible@m tm-aside-column">
                  <section class="uk-card uk-card-default uk-card-small"
                           uk-sticky="offset: 90; bottom: true;">
                    <nav>
                      <ul class="uk-nav uk-nav-default tm-nav">
                        <li><a href="#">About</a></li>
                        <li><a href="#">Contacts</a></li>
                        <li><a href="#">Blog</a></li>

                        <li class="uk-active">
                          <router-link title="News" tag="a" :to="{name: 'news'}"> News
                          </router-link>
                        </li>
                        <li>
                          <router-link title="Faq" tag="a" :to="{name: 'faq'}"> FAQ
                          </router-link>
                        </li>


                      </ul>
                    </nav>
                  </section>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </layout>
</template>

<script scoped>
  import Layout from '../../layouts/Layout'

  export default {
    name: 'Home',
    components: {
      Layout
    }
  }
</script>

<style scoped>
</style>
